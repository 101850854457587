<template>
	<div class="row">
		<div class="col-12">
			<h2>{{ $t('invoice.supplier_control_form') }}</h2>
		</div>

		<div class="col-7">
            <iframe :src="invoice_supplier.suppliersocr_pdf_url" height="1000px" width="100%" style="position:relative"></iframe>
		</div>
		<div v-if="invoice_supplier_local" class="col-5">
			<div class="form-group">
				<label>{{ $t('invoice.entity_invoiced') }} *</label>
				<e-select
					v-model="invoice_supplier_local.entity"
					id="entity_id"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('tiers.rechercher_billing_entity')"
					:selectedLabel="$t('global.selected_label')"
					:options="entity"
					:searchable="true"
					:allow-empty="false"
					:loading="loadingEntity"
					:show-labels="false"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="form-group">
				<label>{{ $t('tiers.tiers') }} *</label>

				<div class="row">
					<div class="col">
						<e-select
							v-model="invoice_supplier_local.tier"
							track-by="tiers_id"
							label="tiers_rs"
							:placeholder="$t('tiers.rechercher')"
							:selectedLabel="$t('global.selected_label')"
							:options="tiers"
							:searchable="true"
							:allow-empty="false"
							:loading="loadingTiers"
							:show-labels="false"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
							<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
					<div class="col-auto">
		                <button class="btn btn-secondary" @click="addTiers"><font-awesome-icon :icon="['far', 'plus']" /></button>
		            </div>
		            <div class="col-12" v-if="pappers_key && invoice_supplier.tier && (invoice_supplier.tier.tiers_siren || invoice_supplier.tier.tiers_siret)">
						<span class="btn btn-link" @click="askPappers">{{ $t('invoice.update_pappers') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></span>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label>{{ $t('invoice.suppliersocr_title') }}</label>
				<input type="text" class="form-control" v-model="invoice_supplier_local.suppliersocr_title">
			</div>
			<div class="form-group">
				<label>{{ $t('invoice.suppliersocr_num') }}</label>
				<input type="text" class="form-control" v-model="invoice_supplier_local.suppliersocr_num">
			</div>
			<div class="form-group">
				<label>{{ $t('invoice.suppliersocr_date') }}</label>
                <e-datepicker v-model="invoice_supplier_local.suppliersocr_date" mode="date"/>
			</div>
			<div class="form-group">
				<label>{{ $t('invoice.suppliersocr_ht') }} *</label>
				<input type="text" class="form-control" v-model="invoice_supplier_local.suppliersocr_ht" :disabled="!can_edit">
			</div>
			<div class="form-group">
				<label>{{ $t('invoice.suppliersocr_ttc') }} *</label>
				<input type="text" class="form-control" v-model="invoice_supplier_local.suppliersocr_ttc" :disabled="!can_edit">
			</div>
			<div v-if="!can_edit">
				<WarnAlert messageI18n="invoice.supplier_edit_ttc" />
			</div>
			<!-- <div class="form-group">
				<label>{{ $t('invoice.suppliersocr_balance') }}</label>
				<input type="text" class="form-control" v-model="invoice_supplier_local.suppliersocr_balance">
			</div> -->
			<div class="d-flex">
				<label>{{ $t('invoice.supplier_duedate') }}</label>
				<a href="#" @click.prevent="add_duedate"><font-awesome-icon :icon="['fal', 'plus']" class="ml-1"/></a>
			</div>
			<div v-for="(duedate, index) in duedates" :key="index" class="row border-top pt-1">
				<div class="col-6 form-group">
					<label>{{ $t('invoice.suppliersocr_duedate') }}</label>
	                <e-datepicker v-model="duedate.isduedate_date" mode="date"/>
				</div>
				<div class="col-6 form-group">
					<label>{{ $t('invoice.suppliersocr_duedate_amount') }}</label>
					<input type="text" class="form-control" v-model="duedate.isduedate_balance">
				</div>
			</div>
			<div class="form-group">
				<label>{{ $t('invoice.suppliersocr_currency') }}</label>
				<CurrencyInput v-model="invoice_supplier_local.suppliersocr_currency" />
			</div>
			<div class="form-group">
				<label>{{ $t('invoice.suppliersocr_memo') }}</label>
				<input type="text" class="form-control" v-model="invoice_supplier_local.suppliersocr_memo">
			</div>
			<div v-if="!edit" class="form-group">
				<b-form-checkbox v-model="invoice_supplier_local.suppliersocr_checked">{{ $t('invoice.suppliersocr_checked') }}</b-form-checkbox>
			</div>
	        <div class="form-group mt-3 text-center">
	            <button @click="save" class="btn btn-primary">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['far', 'save']" /></button>
	        </div>
		</div>
	</div>
</template>

<script type="text/javascript">
    import Tiers from "@/mixins/Tiers.js"
	import Invoice from "@/mixins/Invoice.js"
	import Shutter from "@/mixins/Shutter.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"

	export default {
		name: 'ControlForm',
		mixins: [Tiers, Invoice, Shutter, ShutterInvoice],
		props: ['invoice_supplier', 'edit'],
		data () {
			return {
				invoice_supplier_local: null,
				loadingEntity: false,
				loadingTiers: false,
				entity: [],
				tiers: [],
				processing: false,
				pappers_key: null,
				can_edit: true,
				duedates: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const invoice = this.invoice_supplier
				invoice.suppliersocr_date = invoice.suppliersocr_date ? new Date(invoice.suppliersocr_date) : null
				invoice.suppliersocr_duedate = invoice.suppliersocr_duedate ? new Date(invoice.suppliersocr_duedate) : null
				invoice.suppliersocr_checked = invoice.suppliersocr_ocr_status == 'checked'
				this.invoice_supplier_local = invoice

				this.can_edit = this.invoice_supplier.status == 'to_pay'

				const config = this.getConfig('api_config')
                const pappers = config.find(conf => conf.apiconfig_type == "PAP")
                if(pappers) {
                    this.pappers_key = pappers.apiconfig_key
                }

				this.loadingEntity = true
				const accounting = await this.loadAccountingPlans()
				const accounting_supplier = accounting.filter(acc => acc.accountingplan_supplier)
				this.entity = accounting_supplier.map(acc => acc.tiers)
				this.loadingEntity = false

				this.loadingTiers = true
				this.tiers = await this.loadTiers()
				this.loadingTiers = false

				if(this.invoice_supplier.suppliersocr_tiers) {
					this.invoice_supplier.tier = this.tiers.find(tier => tier.tiers_id == this.invoice_supplier.suppliersocr_tiers)
				}

				this.duedates = this.invoice_supplier.duedates.map(duedate => ({...duedate, isduedate_date: new Date(duedate.isduedate_date), isduedate_balance: duedate.isduedate_balance/100}))
				if(this.duedates.length == 0 && this.invoice_supplier.suppliersocr_duedate) {
					this.duedates.push({
						isduedate_id: 0,
						isduedate_date: this.invoice_supplier.suppliersocr_duedate,
						isduedate_balance: this.invoice_supplier.suppliersocr_balance
					})
				}
			},

			async save() {
				if(!this.invoice_supplier_local.tier || !this.invoice_supplier_local.entity) {
					this.failureToast('invoice.supplier_error_control')
					return false
				}

				this.processing = true

				if(this.invoice_supplier_local.tier.tiers_id < 0) {
					await this.$sync.force()
					this.invoice_supplier_local.tier.tiers_id = this.$sync.replaceWithReplicated('tiers', this.invoice_supplier_local.tier.tiers_id)
				}

				this.invoice_supplier_local.suppliersocr_ttc = this.invoice_supplier_local.suppliersocr_ttc.toString().replace(',', '.')
				this.invoice_supplier_local.suppliersocr_ht = this.invoice_supplier_local.suppliersocr_ht.toString().replace(',', '.')

				if(this.can_edit) {
					this.invoice_supplier_local.suppliersocr_balance = this.invoice_supplier_local.suppliersocr_ttc.toString().replace(',', '.')
				}

				this.invoice_supplier_local.duedates = this.duedates

				const invoice = await this.updateInvoiceSupplier(this.invoice_supplier_local.suppliersocr_id, this.invoice_supplier_local)
				this.processing = false

				this.ok()
				this.shutterPanel().close('invoice-supplier-control-form')	
				if((this.invoice_supplier.suppliersocr_ocr_status == 'ocr_done' || this.invoice_supplier.suppliersocr_ocr_status == 'controlled') && this.invoice_supplier_local.suppliersocr_checked) {
					this.invoice_supplier_local.suppliersocr_balance = parseFloat(this.invoice_supplier_local.suppliersocr_balance)
					this.setUpPaymentInvoiceSupplier([invoice], true)
				}
			},

			addTiers() {
				let prediction = JSON.parse(this.invoice_supplier_local.suppliersocr_ocr_data).prediction

				let siret = prediction.supplier_company_registrations.find(r => r.type == 'SIRET')
				let siren = prediction.supplier_company_registrations.find(r => r.type == 'SIREN')
				let vat = prediction.supplier_company_registrations.find(r => r.type == 'VAT NUMBER')
				let iban = prediction.supplier_payment_details[0]

				let tiers = {
					tiers_rs: prediction.supplier_name.value,
					tiers_address1: prediction.supplier_address.value,
					tiers_siret: siret ? siret.value : null,
					tiers_siren: siren ? siren.value : null,
					tiers_vat_number: vat ? vat.value : null,
					tiers_rib: iban ? iban.iban : null
				}
				
				this.setupAddTier(tiers, this.reloadTiers)
			},

			async reloadTiers(tiers_id) {
				this.shutterPanel().goTo('invoice-supplier-control-form')

				this.loadingTiers = true
				this.tiers = await this.loadTiers()
				this.loadingTiers = false

				this.invoice_supplier_local.tier = this.tiers.find(tier => tier.tiers_id == tiers_id)
			},

			askPappers() {
				this.setupSuggestionPappers(this.invoice_supplier_local.tier, this.reloadTiers)
			},

			add_duedate() {
				this.duedates.push({
					isduedate_id: 0,
					isduedate_date: new Date(),
					isduedate_balance: 0
				})
			}
		},

		watch: {
			'invoice_supplier_local.entity': {
                handler(val) {
                	if(val) {
	                	this.invoice_supplier_local.suppliersocr_entity = val.tiers_id
                	}
                },
                deep: true
            },
			'invoice_supplier_local.tier': {
                handler(val) {
                	if(val) {
	                	this.invoice_supplier_local.suppliersocr_tiers = val.tiers_id
                	}
                },
                deep: true
            }
		},

		components: {
            CurrencyInput : () => import('GroomyRoot/components/Inputs/CurrencyInput'),
            WarnAlert : () => import('GroomyRoot/components/Alert/WarnAlert')
		}
	}

</script>